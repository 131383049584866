
import { notes } from "@/api";
import Vue from "vue";
export default Vue.extend({
  name: "ReportPdfProjectNotes",
  components: {
  },
  props: {
    project: Object,
  },
  data() {
    return {
      note: "",
    };
  },
  computed: {},
  created() {
    this.getAll();
  },
  methods: {
    async getAll() {
      this.note = (await notes.project.get(this.project.id)).data[0]?.note;
    },
  },
});
