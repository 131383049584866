
import { IReportHeaderTemplate, projects } from "@/api";
import { Actions, Mutations } from "@/store/models";
import { bufferToImage, getDate } from "@/utils";
import Vue from "vue";


export default Vue.extend({
  name: "ReportPdfHeader",
  props: {
    item: Object,
    reportId: Number
  },
  components: {
  },
  data() {
    return {
      report: {} as IReportHeaderTemplate,
      edit: false,
      saveTemplateDialog: false,
    };
  },
  computed: {
    lastUpdated: function (): string {
      return getDate(
        this.report.updated_datetime || this.report.created_datetime
      );
    },
    logo: function (): string {
      const icon = this.report.company_icon || ({} as any);
      return icon.data ? bufferToImage(icon.data) : "";
    },
    isPublic: function (): boolean {
      return this.$route.path.toLowerCase().startsWith("/public");
    },
  },
  methods: {
    openWebsite() {
      let url = this.report.domain_name;
      if (!url?.startsWith("http")) {
        url = "https://" + url;
      }
      window.open(url, "_blank");
    },
    openAddress() {
      window.open(
        "https://www.google.com/maps/dir/" + this.report.address,
        "_blank"
      );
    },
    openTel() {
      window.open(`tel:${this.report.phone}`, "_self");
    },
    openMail() {
      window.open(`mailto:${this.report.email}`, "_self");
    },
  },

  watch: {
    item: {
      deep: true,
      immediate: true,
      handler() {
        this.report = JSON.parse(JSON.stringify(this.item)) || {};
      },
    },
  },
});
