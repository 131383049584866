
import Vue from "vue";
import { attachments, IAttachment, notes } from "@/api";
import { Actions } from "@/store/models";

export default Vue.extend({
  name: "ReportPdfResults",
  components: {
  },
  props: {
    project: Object,
    selectAOI: Object,
  },
  data() {
    return {
      items: [] as IAttachment[],
      note: ""
    };
  },
  computed: {
  },
  created() {
    this.getAttachments();
    this.getNote();
  },
  methods: {
    async getAttachments() {
      if (!this.project?.id || !this.selectAOI?.id) {
        return;
      }
      try {
        this.items = (
          await attachments.projects.getAll(this.project.id, this.selectAOI.id)
        ).data;

        this.items.forEach(async (item: any) => {
          item.image = (await attachments.getImageFromPresignedUrl(item.id)).data.presignedUrl
          this.$forceUpdate();
        })
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getImage(item: IAttachment) {
      try {
        // const buffer = (await attachments.getImage(this.item.id)).data;
        // const typedArray: any = new Uint8Array(buffer);
        // this.image = bufferToImage(typedArray);
        const image = (await attachments.getImageFromPresignedUrl(item.id)).data.presignedUrl

        return image
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async getNote() {
      if (!this.project?.id || !this.selectAOI?.id) {
        return;
      }
      this.note = (await notes.areaOfInterest.get(this.selectAOI.id))
        .data[0]?.note;
      
    },
  },
  watch: {
    selectAOI: {
      deep: true,
      immediate: true,
      handler() {
        this.getAttachments();
        this.getNote();
      },
    },
  },
});
