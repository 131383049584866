<template>
  <v-container class="px-0 py-0 report-pdf" fluid>
    <v-overlay opacity="0" :value="true" z-index="999">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="70"
      ></v-progress-circular>
    </v-overlay>
    <!-- report content -->
    <v-container
      class="py-3 px-10"
      fluid
      v-if="!isPublic || (isPublic && report.id)"
    >

      <template>
        <!-- header -->
        <template v-if="report.report_header_template">
          <report-pdf-header
            :item="reportHeader"
            :reportId="report.id"
            class="print"
          />
        </template>

        <!-- report name and created datetime -->
        <v-row justify="space-between" class="pl-1">
          <v-col cols="12" md="4" class="pb-1">
            <h2 style="font-size: 28px">
              {{ report.name }}
            </h2>
          </v-col>
        </v-row>
        <v-row class="pb-3 pb-md-2 pl-1">
          <v-col class="py-0" style="font-size: 18px">
            <b>Report created:&nbsp;</b>
            <span>{{ createdDate }} at {{ createdTime }}</span>
          </v-col>
        </v-row>


        <!-- Customer Name & job name & address -->
        <v-row class="px-1">
          <v-col cols="auto" class="mr-6">
            <span class="details-label">Customer Name</span><br/>
            <span class="details-text">{{ project.customer_name }}</span>
          </v-col>
          <v-col cols="auto" class="mr-6">
            <span class="details-label">Job Code</span><br/>
            <span class="details-text">{{ project.job_code }}</span>
          </v-col>
          <v-col cols="auto" class="mr-6">
            <span class="details-label">Location</span><br/>
            <span class="details-text">{{ project.address }}</span>
          </v-col>
        </v-row>

        <!-- Map -->
        <v-row class="py-5" v-if="project.address">
          <v-col class="pa-0 mx-3 mt-4 gmap-container">
            <google-map :address="project.address" />
          </v-col>
        </v-row>

        <!-- Files -->
        <v-row class="pt-6 px-4 avoid-bread">
          <report-pdf-project-files :project="project" v-if="project.id" />
        </v-row>

        <!-- Notes -->
        <v-row class="pt-6 px-4">
          <report-pdf-project-notes :project="project" v-if="project.id" />
        </v-row>

        <v-row class="px-2 py-9">
          <v-divider style="border-width:2px" />
        </v-row>

        <div
          v-for="selectedAreaOfInterest in areasOfInterest"
          :key="selectedAreaOfInterest.id"
        >
          <!-- area of interest -->
          <v-row class="pt-8">
            <v-col cols="auto" class="d-flex">
              <h2 style="font-size: 28px">Area of Interest: {{ selectedAreaOfInterest.name }}</h2>
            </v-col>
          </v-row>

          <!-- scans; attachments; notes -->
          <v-row>
            <v-container class="area-of-interest-container mx-3" fluid>

              <report-pdf-results
                :project="project"
                :selectAOI="selectedAreaOfInterest"
                v-if="report.sites_data_allowed"
              />
              <project-scans
                ref="scans"
                :project="project"
                :areaOfInterest="selectedAreaOfInterest"
                v-if="report.gpr_data_allowed"
              />
            </v-container>
          </v-row>
        </div>
      </template>
    </v-container>
  </v-container>
</template>

<script>
import { HeaderKeys, projects, setHeaders } from "@/api";
import Vue from "vue";
import ProjectScans from "@/components/project/scans/ProjectScans.vue";
import { getDate, getTime, sizeInMB } from "@/utils";
import html2pdf from "html2pdf.js";
import { attachments } from "@/api";
import { Actions, Mutations } from "@/store/models";
import { BroadcaseMessage, Broadcast } from "@/utils/broadcast";
import ReportPdfHeader from "./ReportPdfHeader.vue";
import GoogleMap from "@/components/common/GoogleMap.vue";
import ReportPdfProjectFiles from "./ReportPdfProjectFiles.vue";
import ReportPdfResults from "./ReportPdfResults.vue";
import { jsPDF } from "jspdf";
import ReportPdfProjectNotes from "./ReportPdfProjectNotes.vue";

export default Vue.extend({
  name: "ReportPdf",
  components: {
    ProjectScans,
    ReportPdfHeader,
    GoogleMap,
    ReportPdfProjectFiles,
    ReportPdfResults,
    ReportPdfProjectNotes,
  },
  data() {
    return {
      report: {},
      project: {},
      areasOfInterest: [],
      reportHeader: {},
    };
  },
  computed: {
    id: function () {
      return Number(this.$route.params.id);
    },
    isLocked: function () {
      return true;
    },
    projectDetails: function () {
      return [
        // {
        //   label: "Project Address",
        //   text: this.project.address,
        //   icon: "mdi-map-marker",
        // },
        {
          label: "File Size",
          text: sizeInMB(this.project.size),
          icon: "mdi-database",
        },
        {
          label: "Last Updated",
          text: getDate(
            this.project.updated_datetime || this.project.created_datetime
          ),
          icon: "mdi-calendar-month",
        },
      ];
    },
    isPublic: function () {
      return !this.id;
    },
    createdDate: function () {
      return getDate(this.report?.created_datetime);
    },
    createdTime: function () {
      return getTime(this.report?.created_datetime);
    },
    name: function () {
      return this.$route.query.name;
    },
    link: function () {
      return window.location.origin + "/public/report/" + this.report.share_key;
    },
  },
  async created() {
    // download only
    if (this.isPublic) {
      this.handleDownload();
      // save / upload to server
    } else {
      this.handleSave();
    }
  },
  methods: {
    async getReport() {
      try {
        this.report = (await projects.reports.get(this.id)).data[0];
        this.project = { ...this.report.project, status: 0 }; // defaulting project to locked state
        await this.getAreasOfInterest();
        await this.getReportHeader();
      } catch (e) {
        this.close();
      }
    },
    async getAreasOfInterest() {
      const r = (await projects.areaOfInterests.getAll(this.project.id)).data;
      const reportAOI = new Set(
        this.report.project_reports_areas_of_interests.map(
          (a) => a.projects_areas_of_interest.id
        )
      );

      this.areasOfInterest = r.filter((a) => reportAOI.has(a.id));
    },
    async getReportHeader() {
      this.reportHeader = {
        ...this.report.report_header_template,
        ...this.report.report_header_json,
      };
    },

    // save
    async handleSave() {
      this.$store.commit(Mutations.SetHideNavDrawer, true);
      await this.getReport();
      setTimeout(async () => {
        // this.save();
        this.jsPDF(this.jsDocSaveCallback)
      }, 10 * 1000);
    },

    // download
    async handleDownload() {
      const password = window.atob(this.$route.query.download);
      setHeaders([
        {
          key: HeaderKeys.Password,
          value: password,
        },
      ]);
      await this.getReport();
      setTimeout(async () => {
        // this.download();
        this.jsPDF(this.jsDocDownloadCallback)
      }, 10 * 1000);
    },

    async jsPDF(callback) {
      const doc = new jsPDF();
      const buttonX = 160; 
      const buttonY = 10;
      const buttonWidth = 40; 
      const buttonHeight = 7; 
      const buttonColor = [0, 123, 255]; 
      const cornerRadius = 3; 
      const iconBase64 = "data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAApVJREFUeF7tmr1OwzAQx22rqlqppkPVhQdAiJWJDcTAE8AzAO+AKC8BPAN9AQYQbB3YEeIBWKoOwZW6VA49RKUo5OOc2I3jOFKXxLnc/+fzxb0cJQ0/aMP1Ew/AR0DDCfgl0PAA8EnQLwHVJTCdTnmn09mXUh6q3ps0vt/vj5LOB0GQeF7lmWm2ozaUIkAIcU4pvQrDcFvFkayxnPNEHwAAY+y66HOklDdaAczn8+MwDJ+KOpR2Xy0AzGazrXa7/UYI2WkkgCAIThhjj7rFg71aREDSeoQ1pgNI0STIGNsjhJym+aA1B6QBwCQZHZDiNoQQD1niYbyzADDinQWQIX4cjwjnIiBLvJTyPb5ncApAlnjO+VmZHIXaCZZ5QNkkmCce7Jfxz2oAGPHOAsCKdxKAinjnAKiKdwpAEfFrACq1BuV6QJksi30LFBWPtZ82zoq3QFXiAUrlAKoUXzmAqsVXCsAG8ZUBsEV8JQBMiE+pII9W5bbcqtVGk6AJ8WkbodV5uwCYEl+LnaBJ8dYDMC3eagCbEG9tDhBCQM0eytfxYwxlrLJ7+Oj91r4FEiBoF29tBKxnKALBiHirc0AUAuccavdGjjJ/1ze6ETKi3uWqMBaYj4CEbhKnvgzlRYKPAB8B/xuq/BLQ2SWmu0Vm9Sk7XM0Q6hWct/7X141+HhdCQFPkC9YZS8Ydcc5f83xBzQJ0h3a73Q+dDZJ5jpW5Tin9WiwWu8PhUOTZQQEAI9AlSgi5yzNoyfULzvk9xhc0ADD21y16a6JhEuMsYswnpfSy1+s9I8b+DlECADdA12ir1ToghMDPpmOyXC4ng8HgW8UpZQAqxusw1gOowyyZ9NFHgEm6dbDtI6AOs2TSRx8BJunWwfYPgq7eXw8b7UoAAAAASUVORK5CYII="
      // Add button as a rounded rectangle
      doc.setFillColor(...buttonColor); // Set the button color
      doc.roundedRect(buttonX, buttonY, buttonWidth, buttonHeight, cornerRadius, cornerRadius, 'F'); // Draw rounded rectangle

      // Set font size for the button text
      doc.setFont('Helvetica'); 
      doc.setFontSize(8);
      doc.setTextColor(255, 255, 255); // Set text color to white

      // Positioning for the icon
      const iconX = buttonX + 6;
      const iconY = buttonY + 1; 

      // Add the icon (Image)
      const iconWidth = 4.5;
      const iconHeight = 4.5;
      doc.addImage(iconBase64, 'JPEG', iconX, iconY, iconWidth, iconHeight); 

      // Add text to the right of the icon
      const textX = iconX + iconWidth + 2;
      const textY = buttonY + 4.5;
      doc.text('LIVE REPORT', textX, textY);
      doc.link(buttonX, buttonY, buttonWidth, buttonHeight, { url: this.link }); // Make the rectangle clickable

      const element = document.getElementById("app"); 
      doc.html(element, {
        x: 0,
        y: 18,
        width: 210, //target width in the PDF document
        windowWidth: 1270, //window width in CSS pixels
        autoPaging: 'text',
        html2canvas: {
          useCORS: true,
          letterRendering: true
        },
        fontFaces: [{
          family: 'Material Icons',
          style: 'normal',
          weight: 'normal',
          src: [{ 
            url: 'https://cdnjs.cloudflare.com/ajax/libs/mdui/0.1.2/icons/material-icons/MaterialIcons-Regular.ttf', 
            format: 'truetype' }]
        }],
        callback: (doc) => callback(doc)
      });
    },
    jsDocDownloadCallback(doc) {
      doc.save(this.report.name);
      setTimeout(async () => {
        // this.close()
      }, 1 * 1000);
    },
    async jsDocSaveCallback(doc) {
      doc.save(this.name);
      const pdfObj = doc.output("blob");
      try {
        // upload pdf to server
        await attachments.reports.add(this.report.project.id, {
          data: pdfObj,
          name: this.name,
        });
        const bc = Broadcast.getInstance();
        bc.postMessage(BroadcaseMessage.PdfGenerated);

      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        setTimeout(async () => {
          this.close()
        }, 1 * 1000);
      }
    },

    
    // deprecated
    async save() {
      const element = document.getElementById("app");
      const opt = {
        margin: 0,
        filename: this.report.name,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          useCORS: true,
          y: 64,
          scale: 4,
        },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },

        pagebreak: {avoid: 'img'}
      };

      try {
        // generate pdf
        const pdfObj = await html2pdf()
          .set(opt)
          .from(element)
          .toPdf()
          .get("pdf");
        const perBlob = pdfObj.output("blob");
        // upload pdf to server
        await attachments.reports.add(this.report.project.id, {
          data: perBlob,
          name: this.name,
        });
        const bc = Broadcast.getInstance();
        bc.postMessage(BroadcaseMessage.PdfGenerated);
        // download pdf - commented out for now
        // await pdfObj.save();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      } finally {
        this.close();
      }
    },
    async download() {
      const element = document.getElementById("app");
      const opt = {
        margin:[15, 15],
        filename: this.report.name,
        image: { type: "jpeg", quality: 1 },
        html2canvas: {
          useCORS: true,
          y: 64,
          scale: 4,
        },
        jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },

        // pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
        pagebreak: {avoid: 'img'}
      };
      html2pdf(element, opt);
      setTimeout(async () => {
        window.close();
      }, 1 * 1000);
    },



    close() {
      window.close();
    },
  },
});
</script>
<style lang="scss">
.report-pdf {
  background: white !important;
  color: black !important;
  letter-spacing: 0.5px;
  div,
  h2,
  h5,
  span,
  input,
  label,
  hr,
  i,
  p,
  textarea {
    color: black !important;
    border-color: rgb(135, 131, 131) !important;
  }
  .utility-bar {
    background: white !important;
  }
  .v-text-field--outlined fieldset {
    color: rgb(135, 131, 131) !important;
  }
  .v-icon {
    color: rgb(135, 131, 131) !important;
  }

  .area-of-interest-container {
    border: $container-border;
    background-color: white;
  }

  .details-label {
    font-size: 14px;
  }
  .details-text {
    font-weight: 500;
    font-size: 18px;
  }
  .gmap-container {
    border-top: $container-border;
    border-bottom: $container-border;
    min-height: 300px;
    cursor: pointer;
  }
}
img {
  page-break-inside: avoid;
}
.bold-text {
  font-weight: 500;
}

@font-face {
	font-family: 'Material Icons';
	font-style: normal;
	font-weight: 400;
	src: url(https://cdnjs.cloudflare.com/ajax/libs/mdui/0.1.2/icons/material-icons/MaterialIcons-Regular.ttf) format('truetype');
}
</style>