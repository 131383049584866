
import Vue from "vue";
import AppBar from "@/components/core/AppBar.vue";
import NavDrawer from "@/components/core/NavDrawer.vue";
import { Actions } from "./store/models";
import ErrorDialog from "@/components/core/ErrorDialog.vue";
import Toast from "@/components/core/Toast.vue";
import Loader from "./components/core/Loader.vue";
import SupportDialog from "./components/core/SupportDialog.vue";
import { navs } from "./mixins";
import Banner from "./components/core/Banner.vue";
import AppFooter from "./components/core/AppFooter.vue";
import ProgressPanel from "./components/core/ProgressPanel.vue";
import { AssociateRoles } from "./api";
import FsnStatusToast from "./components/core/FsnStatusToast.vue";

export default Vue.extend({
  name: "App",
  components: {
    AppBar,
    NavDrawer,
    ErrorDialog,
    Toast,
    Loader,
    SupportDialog,
    Banner,
    // AppFooter,
    ProgressPanel,
    FsnStatusToast,
  },
  mixins: [navs],
  data() {
    return {
      drawer: true,
      initialized: false,
    };
  },
  created() {
    this.$router.onReady(this.initialize);
    // auto hide nav drawer on md
    if (window.innerWidth < 1264) {
      this.drawer = false;
    }
  },
  computed: {
    isSplashView: function (): boolean {
      return (
        this.$route.path === "/" ||
        this.$route.path.includes("/login") ||
        this.$route.path.includes("/signup") ||
        this.$route.path.includes("/verifyemail") ||
        this.$route.path.includes("/registration") ||
        this.$route.path.includes("/jointeam") ||
        this.$route.path.includes("/forgotpassword") ||
        this.$route.path.includes("/billingedit") ||
        this.$route.name === ("ReportPdf")
      );
    },
    isPublicView: function (): boolean {
      return this.$route.path.toLowerCase().startsWith("/public");
    },
    isInvoicePdfView: function (): boolean {
      return this.$route.path.includes("/invoice");
    },
    isContentView: function (): boolean {
      return !this.isSplashView && !this.isPublicView;
    },
    hideNavDrawer: function (): boolean {
      return this.$store.state.hideNavDrawer;
    },
    forceAbsoluteFooter: function (): boolean {
      return this.isSplashView || this.$route.name === "EquipmentProfile";
    },
  },
  methods: {
    toggleNavDrawer(drawer?: boolean) {
      this.drawer = drawer !== undefined ? drawer : !this.drawer;
    },
    async initialize() {
      // always get terms & conditions before setting auth token since they are nlc calls
      await this.$store.dispatch(Actions.GetTermsAndAgreement);

      // get auth token from session storage, if no token, nav to login
      if (this.isContentView || this.$route.name === ("ReportPdf")) {
        const { initialized } = await this.$store.dispatch(Actions.Init);
        if (!initialized) {
          this.$router.push("/");
        }
      }

      // check for permissions
      if (
        this.$route.name === "AccountManagement" &&
        !this.$store.getters.hasPermissionTo(AssociateRoles.ManageAccount)
      ) {
        this.$router.back();
      }
      if (
        this.$route.name === "AccountPlan" &&
        !this.$store.getters.hasPermissionTo(AssociateRoles.ManageAccount)
      ) {
        this.$router.back();
      }

      // to prevent any api calls before auth token is set
      this.initialized = true;
    },
  },
});
