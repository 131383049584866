
import { Actions } from "@/store/models";
import Vue from "vue";

export default Vue.extend({
  name: "ErrorDialog",
  props: {
    dialog: Boolean,
    title: String,
    text: String,
  },
  computed: {
    error: function (): any {
      return this.$store.state.error;
    },
  },
  methods: {
    async onConfirmation() {
      if (typeof this.error?.message == "string") {
        if (this.error?.message?.includes("session has timed out")) {
          this.$store.dispatch(Actions.Logout);
        }
      }
      this.$store.commit('clearError')
    },
  },
  watch: {},
});
