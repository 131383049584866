
import { attachments, IAttachment, INote, notes } from "@/api";
import { Actions } from "@/store/models";
import Vue from "vue";
const DEFAULT_NOTE = "";
export default Vue.extend({
  name: "ReportPdfProjectFiles",
  components: {
  },
  props: {
    isLocked: Boolean,
    project: Object,
  },
  data() {
    return {
      files: [] as IAttachment[],
    };
  },
  computed: {},
  created() {
    this.getFiles();
  },
  methods: {
     async getFiles() {
      try {
        this.files = (await attachments.documents.getAll(this.project.id)).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    getFileType(item: IAttachment) {
      const tokens = item.name.split('.');
      return (tokens[tokens.length-1]).toUpperCase();
    }
  },
});
